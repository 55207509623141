import request from '@/utils/request'
import $http from "@/utils/request";
import { exportDownload } from "@/api/export";

export const getDesData = (data) => {
  return request({
    url: '/hcInformation/get',
    params: {
      ...data
    }
  })
}

export const qutoList = (data) => {
  return request({
    url: '/hcInformation/pagingQuery',
    params: {
      ...data
    }
  })
}

export const getReplacementNewHCList = (data) => {
  return request({
    url: '/hcInformation/getReplacementNewHCList'
  })
}
export const getParentFunction = (data) => {
  return request({
    url: '/hcInformation/getParentFunction'
  })
}
export const getChildFunction = (data) => {
  return request({
    url: '/hcInformation/getChildFunction',
    params: {
      ...data
    }
  })
}
export const getOfficeList = (data) => {
  return request({
    url: '/hcInformationTransfer/getOfficeList',
    params: {
      ...data
    }
  })
}

export const saveHcInformation = (data) => {
  return request({
    url: '/hcInformation/save',
    method: 'POST',
    data
  })
}
export const getCandidateName = (data) => {
  return request({
    url: '/hcInformation/getCandidateName'
  })
}
export const getInhandBP = (data) => {
  return request({
    url: '/hcInformation/getInhandBP'
  })
}
export const getInhandTa = (data) => {
  return request({
    url: '/hcInformation/getInhandTa'
  })
}
export const getUserList = (data) => {
  return request({
    url: '/hcInformation/getUserList',
    params: {
      ...data
    }
  })
}
export const takeBack = (data) => {
  return request({
    url: '/hcInformation/takeBack',
    method: 'POST',
    data
  })
}
export const rejected = (data) => {
  return request({
    url: '/hcInformation/rejected',
    method: 'POST',
    data
  })
}
export const hcInformationDelete = (data) => {
  return request({
    url: '/hcInformation/delete',
    method: 'POST',
    data
  })
}
export const saveOfferProposa = (data) => {
  return request({
    url: '/hcInformation/saveOfferProposa',
    method: 'POST',
    data
  })
}
export const OfferProposal = (data) => {
  return request({
    url: '/hcInformation/getOfferProposalByHcId',
    params: {
      ...data
    }
  })
}
export const checkBuget = (data) => {
  return request({
    url: '/hcInformation/checkBuget',
    method: 'POST',
    data
  })
}
export const getHcList = (data) => {
  return request({
    url: '/hcInformation/getHcList',
    params: {
      ...data
    }
  })
}
export const saveSelectUser = (data) => {
  return request({
    url: '/hcInformation/saveSelectUser',
    method: 'POST',
    data
  })
}
export const approve = (data) => {
  return request({
    url: '/hcInformation/approve',
    method: 'POST',
    data
  })
}
export const saveTransferUser = (data) => {
  return request({
    url: '/hcInformationTransfer/saveTransferUser',
    method: 'POST',
    data
  })
}
export const back = (data) => {
  return request({
    url: '/hcInformationTransfer/back',
    method: 'POST',
    data
  })
}
export const cancel = (data) => {
  return request({
    url: '/hcInformationTransfer/cancel',
    method: 'POST',
    data
  })
}
export const getUserInfo = (data) => {
  return request({
    url: '/hcInformationTransfer/getUserInfo',
    params: {
      ...data
    }
  })
}
export const getUserLeaveInfo = (data) => {
  return request({
    url: '/hcInformationLeave/getUserInfo',
    params: {
      ...data
    }
  })
}
export const getCodeValue = (data) => {
  return request({
    url: '/hcInformationTransfer/getCodeValue',
    params: {
      ...data
    }
  })
}
export const getLineManager = () => {
  return request({
    url: '/hcInformationTransfer/getLineManager'
  })
}
export const saveHc = (data) => {
  return request({
    url: '/hcInformationTransfer/saveHc',
    method: 'POST',
    data
  })
}
export const saveNoHc = (data) => {
  return request({
    url: '/hcInformationTransfer/saveNoHc',
    method: 'POST',
    data
  })
}
export const getCodeLeaveValue = () => {
  return request({
    url: '/hcInformationLeave/getCodeValue'
  })
}
export const saveLeaveForm = (data) => {
  return request({
    url: '/hcInformationLeave/saveLeaveForm',
    method: 'POST',
    data
  })
}
export const rejectLeave = (data) => {
  return request({
    url: '/hcInformationRegular/reject',
    method: 'POST',
    data
  })
}

export const backLeave = (data) => {
  return request({
    url: '/hcInformationLeave/back',
    method: 'POST',
    data
  })
}
export const sendEmail = (data) => {
  return request({
    url: '/hcInformationRegular/sendEmail',
    method: 'POST',
    data
  })
}
export const positive = (data) => {
  return request({
    url: '/hcInformationRegular/positive',
    method: 'POST',
    data
  })
}
export const reject = (data) => {
  return request({
    url: '/hcInformationRegular/reject',
    method: 'POST',
    data
  })
}
export const sendEmailContract = (data) => {
  return request({
    url: '/hcInformationContract/sendEmail',
    method: 'POST',
    data
  })
}
export const renew = (data) => {
  return request({
    url: '/hcInformationContract/renew',
    method: 'POST',
    data
  })
}
export const stopContract = (data) => {
  return request({
    url: '/hcInformationContract/stop',
    method: 'POST',
    data
  })
}

export const saveBeforeUser = (data) => {
  return request({
    url: '/hcInformationRegular/saveBeforeUser',
    method: 'POST',
    data
  })
}
// 获取各部门人员信息
export const departmentPeople = (data) => {
  return request({
    url: '/hcInformationLeave/departmentPeople',
    params: {
      ...data
    }
  })
}
export const sendCheckListEmail = (data) => {
  return request({
    url: '/staff/transfer/sendCheckListEmail',
    params: {
      ...data
    }
  })
}
export const sendExitEmail = (data) => {
  return request({
    url: '/hcInformationLeave/sendExitEmail',
    method: 'POST',
    data
  })
}
export const downloadSelect = (data) => {
  return $http({
    method:'POST',
    url:'/hcInformation/select/download/list',
    responseType: 'arraybuffer',
    data
  })
}

export const downloadAll = () => {
  exportDownload('hcInformation/download/list', {})
}
