<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['userRole']),
    isHfmCodeDisabled() {
      return !this.userRole.includes('HRBP')
    }
  }
}
</script>
