export default {
  inject: ['internData', 'codeValue'],
  computed: {
    getInternData() {
      return this.internData()
    },
    codeValueData() {
      return this.codeValue()
    }
  },

  watch: {
    getInternData: {
      handler(val) {
        this.queryApi(val)
      },
      deep: true
    },
    codeValueData: {
      handler(val) {
        this.queryList(val)
      },
      deep: true
    }
  }
}
