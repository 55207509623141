<template>
  <div class="ContractPosition">
    <el-form
      :model="formInline"
      :rules="rules"
      :key="formKey"
      :inline="true"
      ref="ContractPositionRef"
      :label-position="'top'"
      class="ContractPosition-form"
    >
      <el-row :gutter="12">
        <el-col :lg="6">
          <el-form-item label="HFM Code" prop="hfmCode">
            <el-select
              v-model="formInline.hfmCode"
              clearable
              :disabled="isHfmCodeDisabled"
              @change="changeHfmCode"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in hfmCodeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Legal Entity" prop="legalEntity">
            <el-select
              v-model="formInline.legalEntity"
              clearable
              filterable
              @change="getWorkLocation"
              placeholder=" "
            >
              <el-option
                v-for="item in legalEntityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Working Location" prop="workingLocation">
            <el-select
              v-model="formInline.workingLocation"
              clearable
              filterable
              @change="changeWorking"
              placeholder=" "
            >
              <el-option
                v-for="item in workingLocationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Office Location" prop="officeLocation">
            <el-select
              v-model="formInline.officeLocation"
              clearable
              placeholder=" "
            >
              <el-option
                v-for="item in officeLocationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :lg="6">
          <el-form-item label="Employee type" prop="employeeType">
            <el-input v-model="formInline.employeeType" disabled />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Contract End Date" prop="contractEndDate">
            <el-date-picker
              v-model="formInline.contractEndDate"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item
            label="Assigment/project（Level2)"
            prop="assigmentProjectLevel2"
          >
            <el-select
              v-model="formInline.assigmentProjectLevel2"
              clearable
              placeholder=" "
              @change="checkOrganization(true)"
            >
              <el-option
                v-for="item in assigmentProjectLevel2List"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="!item.enable"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Business Unit Name" prop="businessUnitName">
            <el-select
              v-model="formInline.businessUnitName"
              clearable
              placeholder=" "
            >
              <el-option
                v-for="item in businessUnitNameList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :lg="6">
          <el-form-item label="Description（Level3）">
            <el-select
              v-model="formInline.descriptionLevel3"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in descriptionLevel3List"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="!item.enable"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Client">
            <el-input v-model="formInline.client" />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Department/Team">
            <el-select
              v-model="formInline.departmentId"
              clearable
              placeholder=" "
            >
              <el-option
                v-for="item in departmentIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Business Title in English">
            <el-input
              v-model="formInline.businessTitleInEnglish"
              placeholder=" "
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :lg="6">
          <el-form-item label="Job Grade">
            <!--            <el-input v-model="formInline.jobGrade" placeholder=" " />-->
            <el-select v-model="formInline.jobGrade" clearable placeholder=" ">
              <el-option
                v-for="item in jobGradeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Parent Function" prop="parentFunction">
            <el-select
              v-model="formInline.parentFunction"
              clearable
              @change="changeParentFunction"
              placeholder=" "
            >
              <el-option
                v-for="item in parentFunctionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"

              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Child Function" prop="childFunction">
            <el-select
              v-model="formInline.childFunction"
              clearable
              filterable
              placeholder=" "
            >
              <el-option
                v-for="item in childFunctionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Line Manager LIID" prop="lineManagerLiid">
            <el-input v-model="formInline.lineManagerLiid" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :lg="6">
          <el-form-item label="Line Manager Name" prop="lineManagerName">
            <el-input v-model="formInline.lineManagerName" placeholder=" " />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Line Manager Email" prop="lineManagerEmail">
            <el-input v-model="formInline.lineManagerEmail" placeholder=" " />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Reason for position" prop="reasonForPosition">
            <el-select
              v-model="formInline.reasonForPosition"
              clearable
              placeholder=" "
            >
              <el-option
                v-for="item in reasonForPositionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item
            label="Standard Weekly Hours"
            prop="standardWeeklyHours"
          >
            <el-input-number
              v-model="formInline.standardWeeklyHours"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :lg="6">
          <el-form-item label="Transfer to FTE" prop="transfertoFTE">
            <el-select
              v-model="formInline.transfertoFTE"
              clearable
              @change="changeTransfertoFTE"
              placeholder=" "
            >
              <el-option
                v-for="item in transfertoFTEList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item
            label="Onboarding Date for FTE"
            prop="onboardingDateForFTE"
          >
            <el-date-picker
              v-model="formInline.onboardingDateForFTE"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Floor" prop="floor">
            <el-input v-model="formInline.floor" placeholder=" " />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="CEO" prop="ceo">
            <el-input
              v-model="formInline.ceoName"
              suffix-icon="el-icon-search"
              @click.native="selectCEO('CEO')"
              placeholder="请选择"
              readonly
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <personnelSelectionDialog
      ref="personnelSelectionDialogRef"
      @onConfirm="onConfirm"
    />
  </div>
</template>

<script>
import mixins from '@/views/intern/pages/mixins'
import { check, typeRange } from '@/utils/util'
import {
  getDescriptionLevel3,
  getWorkLocation, getWorkLocationList
} from "@/api/personnelRequisition";
import isHfmCode from '@/views/intern/mixins/isHfmCode'
import {
  getBusinessUnitNameByHfmCode,
  getDepartmentList,
  getEntityHfmCode,
  getOfficeLocation
} from '@/api/intern'
import { getChildFunction } from '@/api/quto'

import { checkOrganization, getSysOrgOrganizations } from "@/api/organization";
import personnelSelectionDialog from "@/components/PersonnelSelectionDialog/PersonnelSelectionDialog.vue";
export default {
  name: 'ContractPosition',
  components: { personnelSelectionDialog },
  mixins: [mixins, isHfmCode],
  created() {
    Object.keys(this.formInline).forEach((item) => {
      this.rules[item] = [
        {
          required: true,
          trigger: 'blur',
          message: 'The field is required'
        }
      ]
    })
    this.rules['lineManagerEmail'].push( { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] })
    // setTimeout(()=>{
    //   this.isFirst = false
    // },2000)
  },
  data() {
    return {
      formInline: {
        hfmCode: '',
        legalEntity: '',
        workingLocation: '',
        officeLocation: '',
        employeeType: '',
        contractEndDate: '',
        assigmentProjectLevel2: '',
        businessUnitName: '',
        departmentId: '',
        businessTitleInEnglish: '',
        jobGrade: '',
        floor: '',
        parentFunction: '',
        childFunction: '',
        client: '',
        descriptionLevel3: '',
        lineManagerLiid: '',
        lineManagerName: '',
        lineManagerEmail: '',
        reasonForPosition: '',
        standardWeeklyHours: '',
        transfertoFTE: '',
        onboardingDateForFTE: '',
        ceo: '',
        ceoName: '',
        // bankName: '',
        // accountNumber: '',
        // accountName: ''
      },
      dialogStatus:'',
      formKey: Math.random(),
      loading: false,
      workingLocationList: [],
      officeLocationList: [],
      assigmentProjectLevel2List: [],
      businessUnitNameList: [],
      departmentIdList: [],
      jobGradeList: [],
      parentFunctionList: [],
      childFunctionList: [],
      reasonForPositionList: [],
      legalEntityList: [],
      transfertoFTEList: [
        {
          label: 'Yes',
          value: 'Yes'
        },
        {
          label: 'No',
          value: 'No'
        }
      ],
      descriptionLevel3List: [],
      onboardingDateForFTEList: [],
      bankNameList: [],
      hfmCodeList: [],
      accountNumberList: [],
      rules: {},
      isFirst:true,
      isFirst1:true,
    }
  },
  methods: {
    getCEO(){
      if(this.isFirst){
        this.isFirst = false
        return
      }
      let params = {
        assigmentProjectId: this.formInline.assigmentProjectLevel2,
        hfm_code: this.formInline.hfmCode,
        legalEntity: this.formInline.legalEntity.split('_')[1],
        workLoaction: this.formInline.workingLocation
      }
      getSysOrgOrganizations(params).then(res=>{
        console.log(res);
        this.formInline.ceoName = res.data.ceoName
        this.formInline.ceo = res.data.ceo
      })
    },
    selectCEO(val) {
      this.dialogStatus = val
      this.$refs.personnelSelectionDialogRef.show({
        type: val,
        hfmCode: this.formInline.hfmCode,
        position: 'Personnel',
        radio:true
      })
    },
    onConfirm(val) {
      console.log(val)
      if (val && val.length) {
        if (this.dialogStatus === 'CEO') {
          this.formInline.ceoName = val[0].userName
          this.formInline.ceo = val[0].id
        }
      }
    },
    async checkOrganization(type = false) {
      if (type) {
        this.getCEO()
        this.formInline.descriptionLevel3 = '';
        let params = {
          assigmentProjectLevel2: this.formInline.assigmentProjectLevel2
        }
        let DescriptionLevel3 = await getDescriptionLevel3(params);
        if (
          DescriptionLevel3 &&
          DescriptionLevel3.code &&
          DescriptionLevel3.code === 200
        ) {
          if (DescriptionLevel3.data) {
            this.descriptionLevel3List = DescriptionLevel3.data
          }
        }
      }

      if(!this.formInline.assigmentProjectLevel2 ||
        !this.formInline.hfmCode || !this.formInline.legalEntity
        || !this.formInline.workingLocation
      ) {
        return true
      } else {
        let params = {
          assigmentProjectId: this.formInline.assigmentProjectLevel2,
          hfm_code: this.formInline.hfmCode,
          legalEntity: this.formInline.legalEntity.split('_')[1],
          workLoaction: this.formInline.workingLocation
        }
        let result = await checkOrganization(params).then(res => {
          let check = true;
          if(res && res.code == 200){
            res.data ? '' : (this.$message.error('所选机构已禁用！'),check = false)
          } else {
            check = false;
          }
          return check;
        })
        return result;
      }
    },
    changeTransfertoFTE(val) {
      if (val === 'No') {
        delete this.rules['onboardingDateForFTE']
        this.formKey = Math.random()
      } else {
        this.rules['onboardingDateForFTE'] = [
          {
            required: true,
            trigger: 'blur',
            message: 'The field is required'
          }
        ]
        this.formKey = Math.random()
      }
    },
    async getWorkLocation(val = false) {
      this.getCEO()
      // let res = await this.checkOrganization();
      // if(!res) {
      //   return;
      // }
      const data = {
        hfmCode: '',
        legalEntity: '',
      }
      let status = true
      Object.keys(data).forEach((item) => {
        if (this.formInline[item].length !== 0) {
          data[item] = this.formInline[item]
        } else {
          status = false
        }
      })
      data.level2Id = this.formInline.assigmentProjectLevel2
      if (status || val) {
        getWorkLocationList(data).then((res) => {
          if (res && res.code === 200) {
            if (check(res.data) === typeRange.array) {
              if (res.data.length) {
                this.workingLocationList = res.data
                if(this.isFirst1){
                  this.isFirst1 = false
                }else{
                  this.formInline.workingLocation = res.data[0].value
                  this.getOfficeLocation()
                }
              }
            }
          }
        })
      }
    },
    async changeHfmCode(val) {
      await this.getWorkLocation()
      let DepartmentList = await getDepartmentList({ hfmCode: val })
      if (DepartmentList && DepartmentList.code === 200) {
        this.departmentIdList = DepartmentList.data
        this.formInline.departmentId = ''
      }
      let NameByHfmCode = await getBusinessUnitNameByHfmCode({ hfmCode: val })
      console.log(NameByHfmCode)
      if (NameByHfmCode && NameByHfmCode.code === 200) {
        this.businessUnitNameList = NameByHfmCode.data
        this.formInline.businessUnitName = ''
      }
    },
    changeWorking(val) {
      this.checkOrganization();
      this.getOfficeLocation();
    },
    async getOfficeLocation() {
      let params = {
        city: this.formInline.workingLocation
      }
      let OfficList = await getOfficeLocation(params)
      if (OfficList && OfficList.code === 200) {
        this.officeLocationList = OfficList.data
        this.formInline.officeLocation = OfficList.data[0].value
      }
    },
    queryApi(val) {
      Object.keys(this.formInline).forEach((item) => {
        this.formInline[item] = val[item] || ''
      })
      this.changeTransfertoFTE(this.formInline.transfertoFTE)
    },
    async getData() {
      let validate = false
      await this.$refs.ContractPositionRef.validate((valid) => {
        validate = !!valid
      })
      if (validate) {
        let res = await this.checkOrganization()
        if(res) {
          return this.formInline
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    },
    async queryList(val) {
      if (val) {
        let HfmCode = await getEntityHfmCode()
        if (HfmCode && HfmCode.code === 200) {
          this.hfmCodeList = HfmCode.data
        }

        let DescriptionLevel3 = await getDescriptionLevel3()
        if (
          DescriptionLevel3 &&
          DescriptionLevel3.code &&
          DescriptionLevel3.code === 200
        ) {
          if (DescriptionLevel3.data) {
            this.descriptionLevel3List = DescriptionLevel3.data
          }
        }
        if (
          val['Legal Entity'] &&
          check(val['Legal Entity']) === typeRange.array
        ) {
          this.legalEntityList = val['Legal Entity']
        }
        if (
          val['Job Grading'] &&
          check(val['Job Grading']) === typeRange.array
        ) {
          this.jobGradeList = val['Job Grading']
        }
        if (
          val['Office Location'] &&
          check(val['Office Location']) === typeRange.array
        ) {
          this.officeLocationList = val['Office Location']
        }
        if (
          val['Working Location'] &&
          check(val['Working Location']) === typeRange.array
        ) {
          this.workingLocationList = val['Working Location']
        }
        if (
          val['Assigment Project'] &&
          check(val['Assigment Project']) === typeRange.array
        ) {
          this.assigmentProjectLevel2List = val['Assigment Project']
        }
        if (val['Department'] && check(val['Department']) === typeRange.array) {
          this.departmentIdList = val['Department']
        }
        if (
          val['Business Unit Name'] &&
          check(val['Business Unit Name']) === typeRange.array
        ) {
          this.businessUnitNameList = val['Business Unit Name']
        }
        if (
          val['Reason for position'] &&
          check(val['Reason for position']) === typeRange.array
        ) {
          this.reasonForPositionList = val['Reason for position']
        }
        if (
          val['Parent Function'] &&
          check(val['Parent Function'] === typeRange.array)
        ) {
          this.parentFunctionList = val['Parent Function']
        }
        if (
          val['Child Function'] &&
          check(val['Child Function'] === typeRange.array)
        ) {
          this.childFunctionList = val['Child Function']
        }
      }
      this.getWorkLocation()
    },
    async changeParentFunction(val) {
      const data = {
        parentFunction: val
      }
      let ChildFunction = await getChildFunction(data)
      if (ChildFunction && ChildFunction.code === 200) {
        this.childFunctionList = ChildFunction.data
        this.formInline.childFunction = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ContractPosition {
  :deep(.el-row) {
    .el-form-item {
      margin-bottom: 0 !important;
    }
    .el-col {
      margin-bottom: 32px;
    }
    &:last-child {
      .el-col {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
