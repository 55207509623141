<template>
  <div
    class="FinanceInfo"
    v-loading="loading"
    element-loading-text="请稍后.."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-form
      :inline="true"
      :model="formInline"
      class="FinanceInfo-form"
      label-position="top"
    >
      <el-row :gutter="12">
        <el-col :lg="6">
          <el-form-item label="Bank Name">
            <el-input v-model="formInline.bankName" />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Account Number">
            <el-input v-model="formInline.accountNumber" />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Account Name">
            <el-input v-model="formInline.accountName" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import mixins from '@/views/intern/pages/mixins'

export default {
  name: 'FinanceInfo',
  mixins: [mixins],
  data() {
    return {
      formInline: {
        bankName: '',
        accountNumber: '',
        accountName: ''
      },
      loading: false
    }
  },
  methods: {
    queryApi(val) {
      Object.keys(this.formInline).forEach((item) => {
        this.formInline[item] = val[item] || ''
      })
    },
    queryList() {},
    getData() {
      return this.formInline
    }
  }
}
</script>

<style lang="scss" scoped>
.FinanceInfo {
  :deep(.el-row) {
    .el-form-item {
      margin-bottom: 0 !important;
    }
    .el-col {
      margin-bottom: 32px;
    }
    &:last-child {
      .el-col {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
