<template>
  <div>
    <div class="EditIntern">
      <div class="EditIntern-right">
        <div class="EditIntern-right-top">
          <div class="titleFlex">
            <div class="internTitle">实习生管理 <span> / 编辑</span></div>
            <div class="EditIntern-right-top-right">
              <el-button @click="Cancel">Cancel</el-button>
              <el-button type="primary" @click="Save">Save</el-button>
            </div>
          </div>
          <div class="flexTitle">
            <div class="EditIntern-right-top-left">
              <h3 style="margin-top: 12px">
                <span class="numberTitle"> {{ internDataGet.seqNumber }}</span>
                &nbsp;&nbsp;<span class="NumbersId">(ID Numbers)</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="EditIntern-right-bottom">
          <div>
            <el-tabs v-model="activeName" @tab-click="onChange">
              <el-tab-pane label="Basic Info" name="BasicInfo"></el-tab-pane>
              <el-tab-pane
                label="Contract&Position"
                name="ContractPosition"
              ></el-tab-pane>
              <el-tab-pane
                label="Finance Info"
                name="FinanceInfo"
              ></el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <div id="BasicInfo">
              <div class="EditIntern-right-bottom-title firstTitle">
                Basic Info
              </div>
              <BasicInfo ref="BasicInfoRef" />
            </div>
            <div id="ContractPosition">
              <div class="EditIntern-right-bottom-title">
                Contract & Position
              </div>
              <ContractPosition ref="ContractPositionRef" />
            </div>
            <div id="FinanceInfo">
              <div class="EditIntern-right-bottom-title">Finance Info</div>
              <FinanceInfo ref="FinanceInfoRef" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anchor from '@/components/anchor/anchor'
import BasicInfo from '@/views/intern/components/BasicInfo'
import ContractPosition from '@/views/intern/components/ContractPosition'
import FinanceInfo from '@/views/intern/components/FinanceInfo'
import { getIntern, taSave } from '@/api/intern'
import { getCodeValue } from '@/api/intern'
export default {
  name: 'EditIntern',
  components: {
    anchor,
    BasicInfo,
    ContractPosition,
    FinanceInfo
  },

  created() {
    this.queryApi()
  },
  data() {
    return {
      internDataGet: {},
      CodeValueData: {},
      id: '',
      activeName: 'BasicInfo'
    }
  },
  provide: function () {
    return {
      internData: () => this.internDataGet,
      codeValue: () => this.CodeValueData
    }
  },
  computed: {
    title() {
      let status = this.$route.query.status
      if (status) {
        if (status === 'edit') {
          return '编辑实习生信息'
        } else {
          return '新增实习生'
        }
      } else {
        return '新增实习生'
      }
    }
  },
  methods: {
    queryApi() {
      let id = this.$route.query.id
      if (id) {
        this.id = id
        const data = {
          id
        }
        getIntern(data).then((res) => {
          if (res && res.code === 200) {
            if (res.data) {
              this.internDataGet = res.data
            }
          }
        })
        getCodeValue(data).then((res) => {
          if (res && res.code === 200) {
            if (res.data) {
              this.CodeValueData = res.data
            }
          }
        })
      } else {
        this.Cancel()
      }
    },
    onChange() {
      let value = this.activeName
      try {
        document.querySelector(`#${value}`).scrollIntoView({
          behavior: 'smooth'
        })
      } catch (err) {}
    },
    Cancel() {
      this.$router.push({
        path: '/intern'
      })
    },
    async Save() {
      let returnData = {
        id: this.id
      }
      let isValid = true
      let BasicInfoRefData = await this.$refs.BasicInfoRef.getData()
      if (BasicInfoRefData) {
        returnData = Object.assign(returnData, { ...BasicInfoRefData })
      } else {
        isValid = false
      }
      let ContractPositionRefData =
        await this.$refs.ContractPositionRef.getData()
      if (ContractPositionRefData) {
        console.log('ContractPositionRefData', ContractPositionRefData)
        returnData = Object.assign(returnData, { ...ContractPositionRefData })
      } else {
        isValid = false
      }
      let FinanceInfoRefData = await this.$refs.FinanceInfoRef.getData()
      if (FinanceInfoRefData) {
        returnData = Object.assign(returnData, { ...FinanceInfoRefData })
      } else {
        isValid = false
      }
      if (isValid) {
        taSave(returnData).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('保存成功')
            this.Cancel()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.EditIntern {
  display: flex;
  .EditIntern-left {
    background: white;
    width: 280px;
    height: calc(100vh - 60px - 130px);
    position: relative;
    .anchor {
      width: 160px;
      height: 170px;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-80px);
    }
  }
  .EditIntern-right {
    width: 100%;
    .EditIntern-right-top {
      background: white;
      height: 100px;
      box-sizing: border-box;
      padding: 20px;

      .EditIntern-right-top-left {
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #323435;
        //line-height: 60px;
        //margin-top: 20px;
      }
    }
    .EditIntern-right-bottom {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 10px 20px 20px;
      height: calc(100vh - 60px - 130px);
      background: white;
      margin-top: 10px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .EditIntern-right-bottom-title {
      font-size: 16px;
      font-weight: 500;
      color: #303133;
      padding-bottom: 5px;
      //margin-bottom: 10px;
      margin-top: 40px !important;
    }
    .numberTitle {
      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #323435;
    }
    .NumbersId {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #868686;
    }
    .internTitle {
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #868686;
      span {
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
    .firstTitle {
      margin-top: 10px !important;
    }
    .titleFlex {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
