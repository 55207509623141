<template>
  <div
    class="BasicInfo"
    v-loading="loading"
    element-loading-text="请稍后.."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-form
      :inline="true"
      :model="formInline"
      :rules="rules"
      class="BasicInfo-form"
      ref="BasicInfoRef"
      :label-position="'top'"
    >
      <el-row :gutter="24">
        <el-col :lg="6">
          <el-form-item label="Local Name" prop="localName">
            <el-input v-model="formInline.localName" />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="English Name">
            <el-input v-model="formInline.englishName" />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item
            label="Personal Mail Address"
            prop="personalMailAddress"
          >
            <el-input v-model="formInline.personalMailAddress" />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Business Email" prop="businessEmail">
            <el-input v-model="formInline.businessEmail" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="6">
          <el-form-item label="Join Date" prop="joinDate">
            <el-date-picker
              v-model="formInline.joinDate"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="HRBP">
            <el-input
              v-model="formInline.hrbpName"
              suffix-icon="el-icon-search"
              @click.native="hrbpClick"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="ID Type">
            <el-select v-model="formInline.idType" clearable>
              <el-option
                v-for="item in idTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="ID/Passport No.">
            <el-input @blur="idChange" v-model="formInline.idNumber" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="6">
          <el-form-item label="Birthday">
            <el-date-picker
              v-model="formInline.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Gender">
            <el-select v-model="formInline.gender" clearable>
              <el-option
                v-for="item in genderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Country">
            <el-select
              v-model="formInline.country"
              @change="countryChange"
              clearable
              filterable
            >
              <el-option
                v-for="item in CountryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Ethnicity">
            <el-select v-model="formInline.ethnicityId" clearable filterable>
              <el-option
                v-for="item in ethnicityIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="6">
          <el-form-item label="Expat/Local">
            <el-select v-model="formInline.expat" :disabled="!isLocal">
              <el-option
                v-for="item in expatList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="户口性质">
            <el-select v-model="formInline.householdRegister">
              <el-option
                v-for="item in householdRegisterList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Mobile Number">
            <el-input v-model="formInline.mobileNumber" />
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Employee LIID">
            <el-input v-model="formInline.employeeLlid" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="6">
          <el-form-item label="Email Account">
            <el-select v-model="formInline.emailAccount" clearable>
              <el-option
                v-for="item in emailAccountList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item label="Career Setting ID">
            <el-input v-model="formInline.careerSettingId" />
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Remark">
            <el-input v-model="formInline.remark" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <personnelSelectionDialog
      ref="personnelSelectionDialogRef"
      @onConfirm="onConfirm"
    />
  </div>
</template>

<script>
import checkIdCard, { check, typeRange } from '@/utils/util'
import mixins from '@/views/intern/pages/mixins'
import personnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'

export default {
  name: 'BasicInfo',
  mixins: [mixins],
  components: {
    personnelSelectionDialog
  },
  created() {
    Object.keys(this.formInline).forEach((item) => {
      if(['businessEmail'].includes(item)){
        this.rules[item] = [
          { type: 'email', message: 'Email format error', trigger: ['blur', 'change'] }
        ]
      }else {
        this.rules[item] = [
          {
            required: true,
            trigger: 'blur',
            message: 'The field is required'
          }
        ]
      }

    })
  },
  computed: {
    isLocal() {
      return false
    }
  },
  data() {
    return {
      formInline: {
        localName: '',
        englishName: '',
        personalMailAddress: '',
        businessEmail: '',
        joinDate: '',
        gender: '',
        hrbpId: '',
        hrbpName: '',
        idType: '',
        idNumber: '',
        birthday: '',
        country: '',
        ethnicityId: '',
        expat: '',
        householdRegister: '',
        mobileNumber: '',
        employeeLlid: '',
        emailAccount: '',
        careerSettingId: '',
        remark: ''
      },
      rules: {},
      loading: false,
      idTypeList: [],
      expatList: [
        {
          label: 'Expat',
          value: 'Expat'
        },
        {
          label: 'Local',
          value: 'Local'
        }
      ],
      genderList: [
        {
          label: 'Male',
          value: 'Male'
        },
        {
          label: 'Female',
          value: 'Female'
        }
      ],
      householdRegisterList: [],
      emailAccountList: [
        {
          label: 'Yes',
          value: 'Yes'
        },
        {
          label: 'No',
          value: 'No'
        }
      ],
      ethnicityIdList: [],
      CountryList: [],
      ethnicityIdListTem: []
    }
  },
  methods: {
    onConfirm(val) {
      if (val) {
        if (check(val) === typeRange.array && val.length) {
          this.formInline.hrbpId = val[0].id
          this.formInline.hrbpName = val[0].userName
        }
      }
    },
    idChange() {
      let idValue = this.formInline.idNumber
      if (checkIdCard(idValue)) {
        let year = idValue.substring(6, 10)
        let month = idValue.substring(10, 12)
        let day = idValue.substring(12, 14)
        if (year && month && day) {
          this.formInline.birthday = `${year}-${month}-${day}`
        }
      } else {
        this.$notify({
          title: '提示',
          message:
            '中国大陆：身份证号码/港澳地区：港澳居民来往内地通行证/台湾地区：台湾居民来往大陆通行证/其他：护照号码 \n' +
            'ID No./Mainland Travel Permit for Hong Kong and Macau Residents/Taiwan Compatriot Permit/Passport',
          type: 'warning'
        })
        this.formInline.birthday = ''
      }
    },
    countryChange(val) {
      if (val === '156') {
        this.formInline.expat = 'Local'
        this.ethnicityIdList = this.ethnicityIdListTem
      } else {
        this.ethnicityIdList = []
        this.formInline.expat = 'Expat'
      }
    },
    hrbpClick() {
      this.$refs.personnelSelectionDialogRef.show({
        position: 'hrbp',
        type: 'HRBP'
      })
    },
    queryApi(val) {
      Object.keys(this.formInline).forEach((item) => {
        this.formInline[item] = val[item] || ''
      })
    },
    async getData() {
      let validate = false
      await this.$refs.BasicInfoRef.validate((valid) => {
        validate = !!valid
      })
      if (validate) {
        return this.formInline
      } else {
        return undefined
      }
    },
    queryList(val) {
      if (val) {
        if (val.Ethnicity && check(val.Ethnicity) === typeRange.array) {
          this.ethnicityIdList = val.Ethnicity
          this.ethnicityIdListTem = val.Ethnicity
        }
        if (
          val['Household Register'] &&
          check(val['Household Register']) === typeRange.array
        ) {
          this.householdRegisterList = val['Household Register']
        }
        if (val.Country && check(val.Country) === typeRange.array) {
          this.CountryList = val.Country
        }
        if (val['ID Type'] && check(val['ID Type']) === typeRange.array) {
          this.idTypeList = val['ID Type']
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.BasicInfo {
  //padding: 30px;
  .BasicInfo-form {
  }
  :deep(.el-row) {
    .el-form-item {
      margin-bottom: 0 !important;
    }
    .el-col {
      margin-bottom: 32px;
    }
    &:last-child {
      .el-col {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
