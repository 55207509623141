import request from '@/utils/request'
import store from '@/store'

export const internList = (data) => {
  return request({
    url: '/intern/list',
    params: {
      ...data
    }
  })
}
export const hrbpSave = (data) => {
  return request({
    url: '/intern/hrbpSave',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'POST',
    data
  })
}
export const getEntityHfmCode = () => {
  return request({
    url: '/hrRafForm/getEntityHfmCode'
  })
}
export const getBusinessUnitNameByHfmCode = (data) => {
  return request({
    url: '/hrRafForm/getBusinessUnitNameByHfmCode',
    params: {
      ...data
    }
  })
}

export const getOfficeLocation = (data) => {
  return request({
    url: '/officeLocation/cityList',
    params: {
      ...data
    }
  })
}

export const getInternalBrandName = () => {
  return request({
    url: '/hrRafForm/getInternalBrandName'
  })
}
export const getParentFunction = () => {
  return request({
    url: '/hcInformation/getParentFunction'
  })
}
export const getChildFunction = () => {
  return request({
    url: '/hcInformation/getChildFunction'
  })
}
export const getDepartmentList = (data) => {
  return request({
    url: '/hrRafForm/getDepartmentList',
    params: {
      ...data
    }
  })
}
export const getCodeValue = (data) => {
  return request({
    url: '/intern/getCodeValue',
    params: {
      ...data
    }
  })
}
export const getLineManager = (data) => {
  return request({
    url: '/intern/getLineManager',
    params: {
      ...data
    }
  })
}
export const getLeaverName = (data) => {
  return request({
    url: '/intern/getLeaverName',
    params: {
      ...data
    }
  })
}
export const etOrganizationsUser = (data) => {
  return request({
    url: '/sysUser/getOrganizationsUser',
    params: {
      ...data
    }
  })
}

export const entryPreparation = (data) => {
  return request({
    url: 'intern/entryPreparation',
    method: 'POST',
    data
  })
}
export const sendInductionEmail = (data) => {
  return request({
    url: 'intern/sendInductionEmail',
    method: 'POST',
    data
  })
}
export const sendITAdminEmail = (data) => {
  return request({
    url: 'intern/sendITAdminEmail',
    method: 'POST',
    data
  })
}
export const exportInternList = (data) => {
  const url = process.env['VUE_APP_BASE_API']
  let baseUrl = `${url}/api/iris/intern/exportInternList?`
  Object.keys(data).forEach((item) => {
    baseUrl += `${item}=${data[item]}&`
  })
  baseUrl += `token=${store.getters.token}`
  console.log(baseUrl)
  window.location = baseUrl
}
export const takeBack = (data) => {
  return request({
    url: 'intern/takeBack',
    method: 'POST',
    data
  })
}
export const saveSelectUser = (data) => {
  return request({
    url: 'intern/saveSelectUser',
    method: 'POST',
    data
  })
}
export const intoOps = (data) => {
  return request({
    url: '/intern/intoOps',
    method: 'POST',
    data
  })
}
export const getIntern = (data) => {
  return request({
    url: '/intern/get',
    params: {
      ...data
    }
  })
}
export const deleteItems = (data) => {
  return request({
    url: '/intern/delete',
    method: 'POST',
    data
  })
}

export const taSave = (data) => {
  return request({
    url: '/intern/taSave',
    method: 'POST',
    data
  })
}
export const sendAttendance = (data) => {
  return request({
    url: '/intern/sendInternAttendanceSingle',
    method: 'POST',
    data
  })
}
export const sendLeave = (data) => {
  return request({
    url: '/intern/sendLeave',
    method: 'POST',
    data
  })
}
export const cancelLeave = (data) => {
  return request({
    url: '/intern/cancelLeave',
    method: 'POST',
    data
  })
}
export const confirmLeave = (data) => {
  return request({
    url: '/intern/confirmLeave',
    method: 'POST',
    data
  })
}
export const getInternInfo = (data) => {
  return request({
    url: '/intern/getInternInfo',
    params: {
      ...data
    }
  })
}
export const getInternInfoTab = (data) => {
  return request({
    url: '/intern/getInternInfoTab',
    params: {
      ...data
    }
  })
}
export const savePositionHistory = (data) => {
  return request({
    url: '/intern/savePositionHistory',
    method: 'POST',
    data
  })
}
export const saveContractHistory = (data) => {
  return request({
    url: '/intern/saveContractHistory',
    method: 'POST',
    data
  })
}
export const upload = (data) => {
  return request({
    url: '/intern/upload',
    method: 'POST',
    data
  })
}
export const candidateUpload = (data) => {
  return request({
    url: '/fileInfo/candidateUpload',
    method: 'POST',
    data
  })
}

export const deleteFile = (data) => {
  return request({
    url: '/intern/deleteFile',
    method: 'POST',
    data
  })
}
export const internshipAgreement = (data) => {
  return request({
    url: '/intern/internshipAgreement',
    params: {
      ...data
    }
  })
}

export const downloadAttach = (data) => {
  const url = process.env['VUE_APP_BASE_API']
  let baseUrl = `${url}/api/iris/intern/downloadAttach?`
  Object.keys(data).forEach((item) => {
    baseUrl += `${item}=${data[item]}&`
  })
  baseUrl += `token=${store.getters.token}`
  console.log(baseUrl)
  window.location = baseUrl
}
/**
 * 打包下载实习证明
 * @param {*} data 
 */
export const batchDownloadInternshipCertificate = (data, downloadFlag) => {

  if (!downloadFlag) {
    return request({
      url: '/intern/batchDownloadInternshipCertificate',
      params: {
        ...data
      }
    })
  }
  const url = process.env['VUE_APP_BASE_API']
  let baseUrl = `${url}/api/iris/intern/batchDownloadInternshipCertificate?`
  Object.keys(data).forEach((item) => {
    baseUrl += `${item}=${data[item]}&`
  })
  baseUrl += `token=${store.getters.token}`
  console.log(baseUrl)
  window.location = baseUrl
}
/**
 * 实习证明下载
 * @param {*} data 
 */
export const downloadInternshipCertificate = (data) => {
  const url = process.env['VUE_APP_BASE_API']
  let baseUrl = `${url}/api/iris/intern/downloadInternshipCertificate?`
  Object.keys(data).forEach((item) => {
    baseUrl += `${item}=${data[item]}&`
  })
  baseUrl += `token=${store.getters.token}`
  console.log(baseUrl)
  window.location = baseUrl
}

export const saveEducation = (data) => {
  return request({
    url: '/intern/saveEducation',
    method: 'POST',
    data
  })
}

export const deleteEducation = (data) => {
  return request({
    url: '/intern/deleteEducation',
    method: 'POST',
    data
  })
}
export const internshipCertificate = (data) => {
  return request({
    url: '/intern/internshipCertificate',
    params: {
      ...data
    }
  })
}
export const recall = (data) => {
  return request({
    url: '/intern/recall',
    method: 'POST',
    data
  })
}
export const rejectOffer = (data) => {
  return request({
    url: '/intern/rejectOffer',
    method: 'POST',
    data
  })
}
export const getOutList = (data) => {
  return request({
    url: '/line-manager/out-list',
    method: 'POST',
    data
  })
}
export const saveOutMember = (data) => {
  return request({
    url: '/line-manager/save',
    method: 'POST',
    data
  })
}
export const deleteInRecruiting = (data) => {
  return request({
    url: '/intern/deleteInRecruiting',
    params: {
      ...data
    }
  })
}
