import request from '@/utils/request'

export const orgOrganizationsList = (data) => {
  return request({
    url: '/orgOrganizations/list',
    params: {
      ...data
    }
  })
}
export const organizationDetail = (data) => {
  return request({
    url: '/orgOrganizations/organizationDetail',
    params: {
      ...data
    }
  })
}
export const getLegalEntity = (data) => {
  return request({
    url: '/sysOrg/getLegalEntity',
    params: {
      ...data
    }
  })
}

export const getLegalEntityCode = () => {
  return request({
    url: '/sysOrg/getLegalEntityCode'
  })
}

export const getHfmCodeList = () => {
  return request({
    url: '/sysUser/getHfmCodeList'
  })
}
export const getOrganizationsUser = (data) => {
  return request({
    url: '/sysUser/getOrganizationsUser',
    params: {
      ...data
    }
  })
}
export const getTransferUserList = (data) => {
  return request({
    url: '/hcInformationTransfer/getTransferUserList',
    params: {
      ...data
    }
  })
}

export const getTaUserList = (data) => {
  return request({
    url: '/hcInformation/getTaUserList',
    params: {
      ...data
    }
  })
}

export const orgOrganizationsEdit = (data) => {
  return request({
    url: '/orgOrganizations/add',
    method: 'POST',
    data
  })
}
export const cityList = () => {
  return request({
    url: '/orgOrganizations/cityList'
  })
}
export const getWorkCity = () => {
  return request({
    url: '/orgOrganizations/getWorkCity'
  })
}
export const getSysOrgOrganizations = (data) => {
  return request({
    url: '/orgOrganizations/getSysOrgOrganizations',
    params: {
      ...data
    }
  })
}
// export const getTransferUserList = (data) => {
//   return request({
//     url: '/sysOrg/getTransferUserList',
//     params: {
//       ...data
//     }
//   })
// }
export const saveNoHc = (data) => {
  return request({
    url: '/hcInformationTransfer/saveNoHc',
    method: 'POST',
    data
  })
}

export const getHcList = (data) => {
  return request({
    url: '/hcInformationTransfer/getHcList',
    params: {
      ...data
    }
  })
}
export const getBeforeRegular = (data) => {
  return request({
    url: '/hcInformationRegular/getBeforeRegular',
    params: {
      ...data
    }
  })
}
export const getDataByWorkCity = (data) => {
  return request({
    url: '/orgOrganizations/getDataByWorkCity',
    params: {
      ...data
    }
  })
}
export const getUserListByType = (data) => {
  return request({
    url: '/sysRole/getUserListByType',
    params: {
      ...data
    }
  })
}

export const changeOrganizationStatus = (data) => {
  return request({
    url: '/orgOrganizations/changeProhibitStatus',
    method: 'POST',
    params: {
      ...data
    }
  })
}

export const checkOrganization = (data) => {
  return request({
    url: '/orgOrganizations/checkOrgProhibitStatus',
    method: 'GET',
    params: {
      ...data
    }
  })
}
