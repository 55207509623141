<template>
  <div class="yc-anchor">
    <div class="yc-anchor-content">
      <div class="yc-anchor-left" />
      <div class="yc-anchor-right">
        <div
          class="yc-anchor-link"
          v-for="item in list"
          :key="item.value"
          :class="item.click ? 'clickPoint' : null"
          @click="clickPoint(item)"
        >
          <div v-if="item.click" class="clickCircle">
            <div class="clickCircle-content"></div>
          </div>
          <template v-if="!item.child">{{ item.label }}</template>
          <div v-else class="yc-anchor-link-item">{{ item.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'anchor',
  data() {
    return {
      list: [
        {
          label: 'Basic Info',
          value: 'BasicInfo',
          child: false,
          click: true
        },
        {
          label: 'Contract & Position',
          value: 'ContractPosition',
          child: false,
          click: false
        },
        {
          label: 'Current Contract',
          value: 'CurrentContract',
          child: true,
          click: false
        },
        {
          label: 'History',
          value: 'History',
          child: true,
          click: false
        },
        {
          label: 'Finance Info',
          value: 'FinanceInfo',
          child: false,
          click: false
        }
      ]
    }
  },
  methods: {
    clickPoint(item) {
      this.list.forEach((item) => {
        item.click = false
      })
      item.click = true
      this.$emit('onChange', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.yc-anchor {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .yc-anchor-content {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 29%;
    transform: translateX(-25%);
    .yc-anchor-left {
      width: 2px;
      height: 100%;
      background: gainsboro;
      margin-right: 10px;
    }
    .yc-anchor-right {
      .yc-anchor-link {
        position: relative;
        margin-top: 20px;
        cursor: pointer;
        &:first-child {
          margin-top: 0;
        }
        .yc-anchor-link-item {
          margin-left: 20px;
        }
        .clickCircle {
          position: absolute;
          width: 13px;
          height: 13px;
          background: $--color-button-primary;
          left: -18px;
          top: 2px;
          border-radius: 50%;
          .clickCircle-content {
            width: 6px;
            height: 6px;
            background: white;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 28%;
            transform: translateX(-50%);
          }
        }
      }

      .clickPoint {
        color: $--color-button-primary;
      }
    }
  }
}
</style>
